import React, { FC } from 'react';

import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const SolarPanel: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.5408 12.396H38.4592V6.23285H41.5408V12.396ZM47.7039 22.1542C47.7039 26.409 44.2548 29.8581 40 29.8581C35.7453 29.8581 32.2961 26.409 32.2961 22.1542C32.2961 17.8995 35.7453 14.4503 40 14.4503C44.2548 14.4503 47.7039 17.8995 47.7039 22.1542ZM44.6223 22.1543C44.6223 19.6014 42.5528 17.5319 40 17.5319C37.4495 17.5376 35.3833 19.6037 35.3777 22.1543C35.3777 24.7071 37.4472 26.7766 40 26.7766C42.5528 26.7766 44.6223 24.7071 44.6223 22.1543ZM55.9214 23.695H49.7583V20.6135H55.9214V23.695ZM24.0786 23.695H30.2417V20.6135H24.0786V23.695ZM41.5408 31.9125H38.4592V38.0756H41.5408V31.9125ZM47.9915 16.3404L45.8139 14.1627L50.1692 9.80745L52.3468 11.9851L47.9915 16.3404ZM34.1861 30.1458L32.0085 27.9681L27.6532 32.3234L29.8309 34.501L34.1861 30.1458ZM32.0085 16.3404L27.6532 11.9851L29.8309 9.80745L34.1861 14.1627L32.0085 16.3404ZM50.1692 34.501L52.3468 32.3234L47.9915 27.9681L45.8139 30.1458L50.1692 34.501ZM19.9699 42.1844L2.50769 73H77.4923L60.0302 42.1844H19.9699ZM47.3239 53.4835H33.0049L31.1868 59.6466H49.142L47.3239 53.4835ZM27.9716 59.6466H13.591L17.0937 53.4835H29.8L27.9716 59.6466ZM35.4496 45.266L33.9191 50.4019H46.4097L44.8792 45.266H35.4496ZM66.4193 59.6466H52.3571L50.5287 53.4835H62.9166L66.4193 59.6466ZM52.203 69.9184L50.0562 62.7281H30.2417L28.1257 69.9184H52.203ZM53.2712 62.7281H68.1757L72.2845 69.9184H55.4078L53.2712 62.7281ZM49.6248 50.4019H61.1601L58.2018 45.266H48.084L49.6248 50.4019ZM21.7983 45.266H32.2961L30.7553 50.4019H18.84L21.7983 45.266ZM27.0575 62.7281H11.8243L7.69498 69.9184H24.9209L27.0575 62.7281Z"
    />
  </Illustration>
);
