import React, { FC } from 'react';

import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const HeatpumpBehind: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path
      d="M60.65 33.69L41.2 10.53C40.92 10.19 40.5 9.99999 40.05 9.98999C39.61 9.98999 39.19 10.18 38.9 10.52L19.35 33.68C19.12 33.95 19 34.29 19 34.65V71.49C19 72.32 19.67 72.99 20.5 72.99H59.5C60.33 72.99 61 72.32 61 71.49V34.65C61 34.3 60.88 33.96 60.65 33.69ZM22 70V35.21L40.05 13.83L58 35.21V44.01H55.5C54.67 44.01 54 44.68 54 45.51C54 46.34 54.67 47.01 55.5 47.01H58V70.01H22V70ZM43 52.5V54.5C43 55.33 42.33 56 41.5 56C40.67 56 40 55.33 40 54.5V52.5C40 51.67 40.67 51 41.5 51C42.33 51 43 51.67 43 52.5ZM45 45.5C45 46.33 44.33 47 43.5 47H43V47.5C43 48.33 42.33 49 41.5 49C40.67 49 40 48.33 40 47.5V45.5C40 44.67 40.67 44 41.5 44H43.5C44.33 44 45 44.67 45 45.5ZM52 45.5C52 46.33 51.33 47 50.5 47H48.5C47.67 47 47 46.33 47 45.5C47 44.67 47.67 44 48.5 44H50.5C51.33 44 52 44.67 52 45.5Z"
      opacity="0.3"
    />
    <path d="M59.5 73.02H41.5C40.67 73.02 40 72.35 40 71.52V59.52C40 58.69 40.67 58.02 41.5 58.02H59.5C60.33 58.02 61 58.69 61 59.52V71.52C61 72.35 60.33 73.02 59.5 73.02ZM43 70.02H58V61.02H43V70.02Z" />
  </Illustration>
);
