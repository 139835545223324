import React, { FC } from 'react';

import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const Boiler: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path d="M47.5613 65.2715L47.561 67.2885C47.561 70.3019 45.1182 72.7447 42.1048 72.7447H37.9077C34.8943 72.7447 32.4515 70.3019 32.4515 67.2885L32.4512 65.2715C33.5964 65.6864 34.8182 65.9418 36.0896 66.0105L36.0889 67.2885C36.0889 68.293 36.9032 69.1073 37.9077 69.1073H42.1048C43.1092 69.1073 43.9235 68.293 43.9235 67.2885L43.9236 66.0105C45.1947 65.9417 46.4162 65.6864 47.5613 65.2715Z" />
    <path d="M43.2241 66.0293H36.7885C31.2113 66.0293 26.4718 62.4431 24.75 57.4508C25.1989 57.5716 25.67 57.6351 26.1559 57.6351L28.7939 57.6354C30.3342 60.4687 33.3368 62.3918 36.7885 62.3918H43.2241C46.6758 62.3918 49.6784 60.4687 51.2188 57.6354L53.8567 57.6351C54.3423 57.6351 54.8131 57.5717 55.2613 57.4526C53.5409 62.4431 48.8013 66.0293 43.2241 66.0293Z" />
    <path d="M26.1574 7.26999H53.8582C56.8716 7.26999 59.3145 9.71282 59.3145 12.7262V52.1789C59.3145 55.1923 56.8716 57.6351 53.8582 57.6351H26.1574C23.144 57.6351 20.7012 55.1923 20.7012 52.1789V12.7262C20.7012 9.71282 23.144 7.26999 26.1574 7.26999ZM26.1574 10.9075C25.1529 10.9075 24.3387 11.7218 24.3387 12.7262V52.1789C24.3387 53.1834 25.1529 53.9977 26.1574 53.9977H53.8582C54.8627 53.9977 55.677 53.1834 55.677 52.1789V12.7262C55.677 11.7218 54.8627 10.9075 53.8582 10.9075H26.1574Z" />
  </Illustration>
);
