import React, { FC } from 'react';

import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const Dryer: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path d="M63.9491 7H16.0512C12.9232 7 10.3789 9.56201 10.3789 12.7117V67.2883C10.3789 70.438 12.9232 73 16.0512 73H63.9491C67.077 73 69.6214 70.438 69.6214 67.2883V12.7117C69.6214 9.56201 67.077 7 63.9491 7ZM14.1604 12.7117C14.1604 11.6618 15.0085 10.8078 16.0512 10.8078H63.9491C64.9918 10.8078 65.8399 11.6618 65.8399 12.7117V20.3267H14.1604V12.7117ZM65.8399 67.2883C65.8399 68.3382 64.9918 69.1922 63.9491 69.1922H16.0512C15.0085 69.1922 14.1604 68.3382 14.1604 67.2883V24.1345H65.8399V67.2883Z" />
    <path d="M52.601 13.6633H48.8204C47.7763 13.6633 46.9297 14.5158 46.9297 15.5672C46.9297 16.6186 47.7763 17.4711 48.8204 17.4711H52.601C53.6451 17.4711 54.4917 16.6186 54.4917 15.5672C54.4917 14.5158 53.6451 13.6633 52.601 13.6633Z" />
    <path d="M62.6888 13.6633H58.9083C57.8642 13.6633 57.0176 14.5158 57.0176 15.5672C57.0176 16.6185 57.8642 17.471 58.9083 17.471H62.6888C63.733 17.471 64.5791 16.6185 64.5791 15.5672C64.5791 14.5158 63.733 13.6633 62.6888 13.6633Z" />
  </Illustration>
);
