import React, { FC } from 'react';

import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const SemiDetachedHouse: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path
      opacity="0.4"
      d="M74.5 70H40C39.6022 70 39.2206 69.842 38.9393 69.5607C38.658 69.2794 38.5 68.8978 38.5 68.5V33.5C38.4991 33.1629 38.6119 32.8352 38.82 32.57L56.12 10.57C56.2607 10.3919 56.4401 10.2481 56.6445 10.1493C56.8489 10.0506 57.073 9.99953 57.3 10C57.528 9.99998 57.7529 10.0523 57.9574 10.1528C58.162 10.2534 58.3408 10.3995 58.48 10.58L75.68 32.58C75.8881 32.8414 76.001 33.1659 76 33.5V68.5C76 68.8978 75.842 69.2794 75.5607 69.5607C75.2794 69.842 74.8978 70 74.5 70ZM41.5 67H73V34L57.29 13.93L41.5 34V67Z"
    />
    <path d="M40 70H5.50001C5.10218 70 4.72065 69.842 4.43934 69.5607C4.15804 69.2794 4.00001 68.8978 4.00001 68.5V33.5C3.99912 33.1629 4.11185 32.8352 4.32001 32.57L21.62 10.57C21.7607 10.3919 21.9401 10.2481 22.1445 10.1493C22.3489 10.0506 22.573 9.99953 22.8 10C23.028 9.99998 23.2529 10.0523 23.4574 10.1528C23.662 10.2534 23.8408 10.3995 23.98 10.58L41.18 32.58C41.3881 32.8414 41.501 33.1659 41.5 33.5V68.5C41.5 68.8978 41.342 69.2794 41.0607 69.5607C40.7794 69.842 40.3978 70 40 70ZM7.00001 67H38.5V34L22.79 13.93L7.00001 34V67Z" />
  </Illustration>
);
