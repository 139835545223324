import React, { FC } from 'react';

import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const Washmachine: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path d="M63.6549 7H16.3483C13.259 7 10.7461 9.56201 10.7461 12.7117V67.2883C10.7461 70.438 13.259 73 16.3483 73H63.6549C66.7443 73 69.2572 70.438 69.2572 67.2883V12.7117C69.2572 9.56201 66.7443 7 63.6549 7ZM14.4809 12.7117C14.4809 11.6618 15.3186 10.8078 16.3483 10.8078H63.6549C64.6847 10.8078 65.5224 11.6618 65.5224 12.7117V20.3267H14.4809V12.7117ZM65.5224 67.2883C65.5224 68.3382 64.6847 69.1922 63.6549 69.1922H16.3483C15.3186 69.1922 14.4809 68.3382 14.4809 67.2883V24.1345H65.5224V67.2883Z" />
    <path d="M52.4489 13.6633H48.7151C47.6838 13.6633 46.8477 14.5158 46.8477 15.5672C46.8477 16.6186 47.6838 17.4711 48.7151 17.4711H52.4489C53.4802 17.4711 54.3163 16.6186 54.3163 15.5672C54.3163 14.5158 53.4802 13.6633 52.4489 13.6633Z" />
    <path d="M62.4098 13.6633H58.676C57.6448 13.6633 56.8086 14.5158 56.8086 15.5672C56.8086 16.6186 57.6448 17.4711 58.676 17.4711H62.4098C63.4411 17.4711 64.2768 16.6186 64.2768 15.5672C64.2768 14.5158 63.4411 13.6633 62.4098 13.6633Z" />
    <path d="M21.9492 46.6633C21.9492 56.8112 30.0466 65.0672 40 65.0672C49.954 65.0672 58.0514 56.8112 58.0514 46.6633C58.0514 46.1694 58.0316 45.6799 57.9941 45.196C57.9941 45.1935 57.9936 45.191 57.9936 45.189C57.2542 35.728 49.467 28.2595 40 28.2595C30.5336 28.2595 22.7464 35.728 22.007 45.189C22.0065 45.191 22.0065 45.1935 22.0065 45.196C21.969 45.6799 21.9492 46.1694 21.9492 46.6633ZM40 61.2595C32.4267 61.2595 26.2091 55.2326 25.7161 47.6367C26.1192 47.8059 26.4762 48.0294 26.8837 48.2847C27.9851 48.9761 29.3566 49.8367 31.9081 49.8367C34.4595 49.8367 35.8306 48.9766 36.9325 48.2852C37.8457 47.7122 38.505 47.2983 39.9996 47.2983C41.4941 47.2983 42.1539 47.7122 43.0671 48.2852C44.169 48.9761 45.5405 49.8367 48.092 49.8367C50.6435 49.8367 52.015 48.9766 53.1169 48.2852C53.5243 48.0294 53.8814 47.8059 54.2839 47.6367C53.7915 55.2326 47.5739 61.2595 40 61.2595ZM40 32.0672C46.9096 32.0672 52.6916 37.0835 54.0266 43.7317C52.7647 44.0369 51.8999 44.5777 51.1596 45.0419C50.2464 45.615 49.5865 46.0289 48.092 46.0289C46.5975 46.0289 45.9376 45.615 45.0244 45.0419C43.9225 44.3506 42.5515 43.49 39.9996 43.49C37.4481 43.49 36.077 44.3506 34.9752 45.0419C34.062 45.615 33.4021 46.0289 31.9081 46.0289C30.4136 46.0289 29.7537 45.615 28.8405 45.0419C28.1002 44.5772 27.2359 44.0364 25.974 43.7317C27.309 37.0835 33.091 32.0672 40 32.0672Z" />
  </Illustration>
);
