import React, { FC } from 'react';

import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const Placeholder: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="4" width="72" height="72" fill="#7A7E9C" />
    </svg>
  </Illustration>
);
