import React, { FC } from 'react';

import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const HeatpumpSide: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path
      d="M70 52.5V54.5C70 55.33 69.33 56 68.5 56C67.67 56 67 55.33 67 54.5V52.5C67 51.67 67.67 51 68.5 51C69.33 51 70 51.67 70 52.5ZM68.5 44H66.5C65.67 44 65 44.67 65 45.5C65 46.33 65.67 47 66.5 47H67V47.5C67 48.33 67.67 49 68.5 49C69.33 49 70 48.33 70 47.5V45.5C70 44.67 69.33 44 68.5 44ZM61.5 44H59.5C58.67 44 58 44.67 58 45.5C58 46.33 58.67 47 59.5 47H61.5C62.33 47 63 46.33 63 45.5C63 44.67 62.33 44 61.5 44ZM56 45.5C56 46.33 55.33 47 54.5 47H52V71.5C52 72.33 51.33 73 50.5 73H11.5C10.67 73 10 72.33 10 71.5V34.66C10 34.31 10.12 33.96 10.35 33.69L29.91 10.53C30.2 10.19 30.62 10 31.06 10C31.5 10 31.92 10.2 32.21 10.54L51.66 33.7C51.89 33.97 52.01 34.31 52.01 34.66V44H54.51C55.34 44 56.01 44.67 56.01 45.5H56ZM49 35.2L31.05 13.83L13 35.21V70H49V35.2Z"
      opacity="0.3"
    />
    <path d="M68.5 73H50.5C49.67 73 49 72.33 49 71.5V59.5C49 58.67 49.67 58 50.5 58H68.5C69.33 58 70 58.67 70 59.5V71.5C70 72.33 69.33 73 68.5 73ZM52 70H67V61H52V70Z" />
  </Illustration>
);
