import React, { FC } from 'react';

import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const HeatpumpShed: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path
      d="M68.5 73H11.5C10.67 73 10 72.33 10 71.5V34.66C10 34.31 10.12 33.96 10.35 33.69L29.91 10.53C30.2 10.19 30.62 10 31.06 10C31.5 10 31.92 10.2 32.21 10.54L51.66 33.7C51.89 33.97 52.01 34.31 52.01 34.66V52.01H68.52C68.92 52.01 69.3 52.16 69.58 52.44C69.86 52.72 70.02 53.1 70.02 53.5V71.5C70.02 72.33 69.34 73 68.51 73H68.5ZM13 70H67V55H50.5C50.1 55 49.72 54.84 49.44 54.56C49.16 54.28 49 53.9 49 53.5V35.2L31.05 13.83L13 35.21V70Z"
      opacity="0.3"
    />
    <path d="M68.51 55H50.49C49.66 55 48.99 54.33 48.99 53.5V41.5C48.99 40.67 49.66 40 50.49 40H68.51C69.34 40 70.01 40.67 70.01 41.5V53.5C70.01 54.33 69.34 55 68.51 55ZM51.99 52H67.01V43H51.99V52Z" />
  </Illustration>
);
