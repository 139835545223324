import React, { FC } from 'react';

import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const HeatpumpFront: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path
      d="M60.65 33.69L41.2 10.53C40.92 10.19 40.5 9.99999 40.05 9.98999C39.61 9.98999 39.19 10.18 38.9 10.52L19.35 33.68C19.12 33.95 19 34.29 19 34.65V71.49C19 72.32 19.67 72.99 20.5 72.99H59.5C60.33 72.99 61 72.32 61 71.49V34.65C61 34.3 60.88 33.96 60.65 33.69ZM30 70V61H34V70H30ZM37 70V59.5C37 58.67 36.33 58 35.5 58H28.5C27.67 58 27 58.67 27 59.5V70H22V35.21L40.05 13.83L58 35.21V44.01H55.54C54.71 44.01 54.04 44.68 54.04 45.51C54.04 46.34 54.71 47.01 55.54 47.01H58V70.01H37V70ZM43.04 52.5V54.5C43.04 55.33 42.37 56 41.54 56C40.71 56 40.04 55.33 40.04 54.5V52.5C40.04 51.67 40.71 51 41.54 51C42.37 51 43.04 51.67 43.04 52.5ZM45.04 45.5C45.04 46.33 44.37 47 43.54 47H43.04V47.5C43.04 48.33 42.37 49 41.54 49C40.71 49 40.04 48.33 40.04 47.5V45.5C40.04 44.67 40.71 44 41.54 44H43.54C44.37 44 45.04 44.67 45.04 45.5ZM52.04 45.5C52.04 46.33 51.37 47 50.54 47H48.54C47.71 47 47.04 46.33 47.04 45.5C47.04 44.67 47.71 44 48.54 44H50.54C51.37 44 52.04 44.67 52.04 45.5Z"
      opacity="0.3"
    />
    <path d="M59.54 73H41.54C40.71 73 40.04 72.33 40.04 71.5V59.5C40.04 58.67 40.71 58 41.54 58H59.54C60.37 58 61.04 58.67 61.04 59.5V71.5C61.04 72.33 60.37 73 59.54 73ZM43.04 70H58.04V61H43.04V70Z" />
  </Illustration>
);
