import React, { FC } from 'react';

import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const Freezer: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path d="M39.4221 11.5445C40.4917 11.5445 41.4959 11.8258 42.3627 12.3172C41.3218 13.1738 40.3865 14.1506 39.5768 15.2272L39.4221 15.2191H14.4195C13.2097 15.2191 12.2255 16.1899 12.2255 17.3831V30.1911L36.8791 30.1927C37.2624 31.4894 37.7969 32.7212 38.4622 33.8677L12.2255 33.8657V67.2332C12.2255 68.4264 13.2097 69.3972 14.4195 69.3972H39.4221C40.6314 69.3972 41.6156 68.4264 41.6156 67.2332L41.6156 37.8071C42.7289 38.8378 43.9811 39.7207 45.341 40.4244L45.3411 67.2336C45.3411 70.4529 42.686 73.0718 39.4221 73.0718H14.4195C11.1556 73.0718 8.5 70.4529 8.5 67.2336V17.3831C8.5 14.1634 11.1556 11.5445 14.4195 11.5445H39.4221Z" />
    <path d="M17.144 18.4177C16.1151 18.4177 15.2812 19.2406 15.2812 20.255V25.1547C15.2812 26.1696 16.1151 26.992 17.144 26.992C18.1725 26.992 19.0068 26.1696 19.0068 25.1547V20.255C19.0068 19.2406 18.1725 18.4177 17.144 18.4177Z" />
    <path d="M17.144 45.0214C18.1725 45.0214 19.0068 44.1989 19.0068 43.1841V38.2843C19.0068 37.2694 18.1725 36.447 17.144 36.447C16.1151 36.447 15.2812 37.2694 15.2812 38.2843V43.1841C15.2812 44.1989 16.1151 45.0214 17.144 45.0214Z" />
    <path d="M63.7167 10.3254C55.4051 4.43716 43.894 6.4015 38.0057 14.713C37.7844 15.0328 37.5753 15.3524 37.354 15.6967C36.1147 17.7266 35.2796 19.977 34.8949 22.324C34.7257 23.3233 34.6435 24.3352 34.6487 25.3487C34.642 28.7708 35.5917 32.1266 37.3908 35.0376C37.5752 35.3327 37.7596 35.6277 37.9563 35.886C38.5413 36.7257 39.1953 37.5154 39.9113 38.2467C40.468 38.8199 41.0638 39.354 41.6941 39.845C44.9371 42.413 48.9554 43.8044 53.092 43.792C57.2287 43.8054 61.2473 42.4138 64.4898 39.845C65.8701 38.7543 67.087 37.4718 68.104 36.0364C73.9926 27.7248 72.0281 16.2137 63.7167 10.3254ZM65.6914 35.1396C60.2589 42.0978 50.2143 43.3347 43.256 37.9022C42.6994 37.4811 42.1733 37.0209 41.6821 36.5251C41.0561 35.8852 40.4843 35.1943 39.9731 34.4595C39.7887 34.2135 39.6289 33.9677 39.4813 33.7218C37.9256 31.2063 37.1037 28.3062 37.1083 25.3486C37.1098 24.4705 37.188 23.5941 37.3419 22.7296C37.6758 20.7007 38.3981 18.7553 39.4691 17C39.6535 16.7171 39.8379 16.4221 40.0346 16.1394C43.0278 11.8934 47.8975 9.36667 53.0924 9.36451C61.9202 9.36451 69.0764 16.5209 69.0764 25.3486C69.0664 28.8976 67.8753 32.3423 65.6914 35.1396Z" />
    <path d="M53.0928 11.8236C52.4137 11.8236 51.8633 12.3741 51.8633 13.0531V37.6441C51.8633 38.3232 52.4137 38.8736 53.0928 38.8736C53.7719 38.8736 54.3223 38.323 54.3223 37.6441V13.0531C54.3223 12.3741 53.7719 11.8236 53.0928 11.8236Z" />
    <path d="M53.7822 15.7212L50.0936 13.2621C49.5544 12.9434 48.8618 13.0808 48.4853 13.5814C48.0771 14.124 48.1862 14.8949 48.7288 15.3031L52.4174 17.7622C52.6174 17.8967 52.8527 17.9694 53.0936 17.9713C53.5041 17.9722 53.888 17.7683 54.1169 17.4275C54.4957 16.864 54.3457 16.1 53.7822 15.7212Z" />
    <path d="M57.7759 13.6949C57.4304 13.1104 56.6764 12.9167 56.0918 13.2622L52.4032 15.7214C52.0624 15.9503 51.8585 16.3342 51.8594 16.7447C51.861 17.4238 52.4127 17.973 53.0916 17.9715C53.3327 17.9696 53.5678 17.8968 53.7678 17.7624L57.4565 15.3032C57.9572 14.9267 58.0946 14.2341 57.7759 13.6949Z" />
    <path d="M54.124 33.1784C53.7159 32.6358 52.945 32.5267 52.4023 32.9349L48.7137 35.394C48.3729 35.623 48.1691 36.0068 48.1699 36.4173C48.1715 37.0964 48.7232 37.6457 49.4022 37.6441C49.6432 37.6422 49.8784 37.5695 50.0784 37.435L53.767 34.9759C53.8062 34.9527 53.8439 34.9275 53.8804 34.9001C54.4233 34.4919 54.5322 33.7212 54.124 33.1784Z" />
    <path d="M57.4717 35.394L53.783 32.9349C53.2439 32.6162 52.5512 32.7536 52.1747 33.2542C51.7665 33.7968 51.8756 34.5677 52.4182 34.9759L56.1069 37.435C56.3069 37.5695 56.5422 37.6422 56.7831 37.6441C57.1936 37.645 57.5774 37.4411 57.8064 37.1003C58.1852 36.5367 58.0352 35.7727 57.4717 35.394Z" />
    <path d="M65.3887 24.119H40.7979C40.1188 24.119 39.5684 24.6695 39.5684 25.3485C39.5684 26.0276 40.1188 26.578 40.7979 26.578H65.3888C66.0679 26.578 66.6183 26.0276 66.6183 25.3485C66.6183 24.6695 66.0678 24.119 65.3887 24.119Z" />
    <path d="M45.1863 24.4289C44.6437 24.0207 43.8728 24.1297 43.4646 24.6724L41.0055 28.361C40.8745 28.56 40.8041 28.7927 40.8028 29.0309C40.7993 29.7099 41.3468 30.2632 42.0259 30.2667C42.4348 30.2673 42.8174 30.0645 43.0465 29.7256L45.5056 26.037C45.8245 25.498 45.6869 24.8054 45.1863 24.4289Z" />
    <path d="M45.5074 24.6601L43.0482 20.9715C42.6717 20.4709 41.9791 20.3333 41.4399 20.6522C40.8554 20.9977 40.6617 21.7517 41.0072 22.3363L43.4664 26.0249C43.6926 26.3684 44.0756 26.576 44.4869 26.5782C44.733 26.5786 44.9735 26.5051 45.1774 26.3672C45.7399 25.987 45.8878 25.2226 45.5074 24.6601Z" />
    <path d="M65.1773 28.3488L62.7182 24.6601C62.3417 24.1596 61.649 24.022 61.1099 24.3408C60.5253 24.6863 60.3316 25.4404 60.6772 26.0249L63.1363 29.7136C63.3625 30.0571 63.7455 30.2647 64.1569 30.2669C64.403 30.2673 64.6434 30.1938 64.8473 30.0559C65.4097 29.6757 65.5575 28.9113 65.1773 28.3488Z" />
    <path d="M64.8581 20.7402C64.3154 20.332 63.5446 20.4411 63.1364 20.9837L60.6772 24.6723C60.5463 24.8713 60.4758 25.104 60.4746 25.3422C60.4712 26.0213 61.0187 26.5746 61.6978 26.578C62.1067 26.5786 62.4893 26.3759 62.7184 26.037L65.1775 22.3484C65.4962 21.8093 65.3588 21.1167 64.8581 20.7402Z" />
    <path d="M60.1021 30.6357L47.8067 18.3403C47.3463 17.9461 46.6675 17.9461 46.2071 18.3403C45.6913 18.7819 45.6314 19.5581 46.073 20.0739L58.3684 32.3694C58.6004 32.5995 58.9145 32.7278 59.2414 32.726C59.5573 32.7215 59.8594 32.5957 60.0849 32.3745C60.5698 31.899 60.5776 31.1205 60.1021 30.6357Z" />
    <path d="M60.1018 18.462C59.6601 17.9462 58.8839 17.8863 58.3681 18.3279L46.0727 30.6233C45.8381 30.8558 45.7072 31.1731 45.709 31.5034C45.7129 32.1825 46.2666 32.7297 46.9457 32.7258C47.2701 32.7212 47.5794 32.5886 47.8064 32.357L60.1018 20.0615C60.496 19.6012 60.496 18.9224 60.1018 18.462Z" />
  </Illustration>
);
