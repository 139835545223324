import React, { FC } from 'react';

import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const Aquarium: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path d="M66.1 11H13.9C12.2984 11 11 12.2984 11 13.9V14.8667C11.0034 16.0926 11.7775 17.184 12.9333 17.5927V62.4073C11.7775 62.816 11.0034 63.9074 11 65.1333V66.1C11 67.7016 12.2984 69 13.9 69H66.1C67.7016 69 69 67.7016 69 66.1V65.1333C68.9966 63.9074 68.2225 62.816 67.0667 62.4073V17.5927C68.2225 17.184 68.9966 16.0926 69 14.8667V13.9C69 12.2984 67.7016 11 66.1 11ZM65.1333 17.7667V62.2333H14.8667V17.7667H65.1333ZM67.0667 66.1C67.0667 66.6339 66.6339 67.0667 66.1 67.0667H13.9C13.3661 67.0667 12.9333 66.6339 12.9333 66.1V65.1333C12.9333 64.5995 13.3661 64.1667 13.9 64.1667H66.1C66.6339 64.1667 67.0667 64.5995 67.0667 65.1333V66.1ZM67.0667 14.8667C67.0667 15.4005 66.6339 15.8333 66.1 15.8333H13.9C13.3661 15.8333 12.9333 15.4005 12.9333 14.8667V13.9C12.9333 13.3661 13.3661 12.9333 13.9 12.9333H66.1C66.6339 12.9333 67.0667 13.3661 67.0667 13.9V14.8667Z" />
    <path d="M24.0013 33.7553L18.3269 39.9323C16.3077 42.1666 16.3077 45.5668 18.3269 47.801L24.0013 53.978C26.0066 56.151 28.8261 57.3909 31.7829 57.4H43.9243C49.562 57.372 54.5919 53.8519 56.5489 48.5647C58.7336 49.3573 59.3329 49.6667 60.1546 49.6667C61.0405 49.7003 61.895 49.3362 62.4843 48.6739C63.0736 48.0117 63.336 47.1206 63.1996 46.2447C62.7743 43.8473 62.7743 43.9247 62.8129 43.712L63.1996 41.4887C63.3481 40.4324 62.9275 39.3762 62.0936 38.711C61.2597 38.0459 60.1365 37.8707 59.1396 38.2503L56.5876 39.1687C56.214 38.1456 55.7173 37.1718 55.1086 36.2687C56.7693 33.744 56.9062 30.5104 55.465 27.8543C54.0238 25.1983 51.2381 23.5505 48.2163 23.5666H41.1016C38.2794 23.5572 35.5709 24.6782 33.5809 26.6793L29.7143 30.546C27.5196 30.9877 25.5203 32.1108 24.0013 33.7553ZM43.9243 55.4667H31.7829C29.3687 55.4635 27.0658 54.4505 25.4319 52.673L19.7576 46.496C18.3822 45.0129 18.3822 42.7204 19.7576 41.2373L25.4319 35.0603C27.0658 33.2829 29.3687 32.2699 31.7829 32.2667H43.9243C50.3308 32.2667 55.5243 37.4602 55.5243 43.8667C55.5243 50.2732 50.3308 55.4667 43.9243 55.4667ZM59.7486 40.0677C60.1001 39.9047 60.5122 39.9439 60.8267 40.1703C61.1412 40.3966 61.3092 40.7749 61.2663 41.16C60.7153 44.379 60.7153 43.364 61.2663 46.5733C61.3035 46.9506 61.1389 47.3195 60.8332 47.5437C60.5276 47.768 60.1263 47.8144 59.7776 47.6657L57.1096 46.699C57.5204 44.8266 57.5204 42.8874 57.1096 41.015L59.7486 40.0677ZM34.9536 28.052C36.58 26.4145 38.7937 25.4956 41.1016 25.5H48.2163C50.408 25.5039 52.4393 26.6495 53.5766 28.5232C54.7138 30.3968 54.7925 32.7276 53.7843 34.6737C51.2497 31.911 47.6735 30.3368 43.9243 30.3333H32.6626L34.9536 28.052Z" />
    <path d="M31.8321 34.3643C31.3929 34.0777 30.8053 34.1935 30.5077 34.6253C30.2107 35.0614 30.3183 35.6551 30.7494 35.9593C30.7494 35.9593 33.2337 37.8057 33.2337 43.8667C33.2337 49.9277 30.8171 51.716 30.7494 51.774C30.4036 52.0154 30.2541 52.4531 30.3802 52.8556C30.5062 53.258 30.8787 53.5323 31.3004 53.5334C31.4904 53.5355 31.6764 53.478 31.8321 53.369C31.9771 53.282 35.1671 51.0683 35.1671 43.8667C35.1671 36.665 31.9771 34.4513 31.8321 34.3643Z" />
    <path d="M27.4332 38.0667C25.8316 38.0667 24.5332 39.3651 24.5332 40.9667C24.5332 42.5683 25.8316 43.8667 27.4332 43.8667C29.0348 43.8667 30.3332 42.5683 30.3332 40.9667C30.3332 39.3651 29.0348 38.0667 27.4332 38.0667ZM27.4332 41.9334C26.8993 41.9334 26.4665 41.5006 26.4665 40.9667C26.4665 40.4328 26.8993 40 27.4332 40C27.9671 40 28.3999 40.4328 28.3999 40.9667C28.3999 41.5006 27.9671 41.9334 27.4332 41.9334Z" />
    <path d="M46.3895 39.7003L39.1201 39.0333C38.5863 38.9853 38.1145 39.3791 38.0665 39.913C38.0184 40.4469 38.4123 40.9186 38.9461 40.9667L46.2155 41.624C47.6322 41.7434 48.7214 42.9282 48.7214 44.35C48.7214 45.7718 47.6322 46.9566 46.2155 47.076L38.9461 47.7333C38.6008 47.7644 38.2982 47.9774 38.1525 48.292C38.0067 48.6066 38.0399 48.9751 38.2395 49.2587C38.4391 49.5422 38.7748 49.6977 39.1201 49.6667L46.3895 48.9997C48.7936 48.7802 50.634 46.7641 50.634 44.35C50.634 41.9358 48.7936 39.9198 46.3895 39.7003Z" />
  </Illustration>
);
