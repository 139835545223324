import React, { FC } from 'react';

import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const InsulationCavityWall: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path
      opacity=".4"
      d="M72.53 37.34L41 7.41004C40.7227 7.14476 40.3537 6.9967 39.97 6.9967C39.5862 6.9967 39.2173 7.14476 38.94 7.41004L7.46997 37.34C7.32201 37.4741 7.20229 37.6363 7.11783 37.8172C7.03337 37.9981 6.98587 38.194 6.97812 38.3935C6.97037 38.593 7.00252 38.7921 7.07269 38.979C7.14286 39.1659 7.24963 39.3369 7.38675 39.482C7.52387 39.6271 7.68857 39.7434 7.87121 39.824C8.05385 39.9046 8.25075 39.948 8.45036 39.9515C8.64997 39.9551 8.84829 39.9187 9.03367 39.8446C9.21906 39.7705 9.38779 39.6602 9.52997 39.52L13 36.22V71.5C13 71.8979 13.158 72.2794 13.4393 72.5607C13.7206 72.842 14.1021 73 14.5 73H65.5C65.8978 73 66.2793 72.842 66.5606 72.5607C66.8419 72.2794 67 71.8979 67 71.5V36.22L70.47 39.52C70.7608 39.7834 71.1432 39.9224 71.5352 39.9072C71.9273 39.892 72.2978 39.7237 72.5672 39.4386C72.8367 39.1534 72.9837 38.774 72.9768 38.3817C72.9698 37.9894 72.8094 37.6155 72.53 37.34V37.34ZM64 70H16V33.37L40 10.57L64 33.37V70Z"
    />
    <path d="M23 38.5V64.5C23 64.8978 22.842 65.2794 22.5607 65.5607C22.2794 65.842 21.8978 66 21.5 66C21.1022 66 20.7206 65.842 20.4393 65.5607C20.158 65.2794 20 64.8978 20 64.5V38.5C20 38.1022 20.158 37.7206 20.4393 37.4393C20.7206 37.158 21.1022 37 21.5 37C21.8978 37 22.2794 37.158 22.5607 37.4393C22.842 37.7206 23 38.1022 23 38.5V38.5ZM58.5 66C58.8978 66 59.2794 65.842 59.5607 65.5607C59.842 65.2794 60 64.8978 60 64.5V38.5C60 38.1022 59.842 37.7206 59.5607 37.4393C59.2794 37.158 58.8978 37 58.5 37C58.1022 37 57.7206 37.158 57.4393 37.4393C57.158 37.7206 57 38.1022 57 38.5V64.5C57 64.8978 57.158 65.2794 57.4393 65.5607C57.7206 65.842 58.1022 66 58.5 66Z" />
  </Illustration>
);
