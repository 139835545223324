import React, { FC } from 'react';

import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const InsulationRoof: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path
      opacity=".4"
      d="M72.53 37.34L41 7.41004C40.7227 7.14476 40.3537 6.9967 39.97 6.9967C39.5862 6.9967 39.2173 7.14476 38.94 7.41004L7.46997 37.34C7.32201 37.4741 7.20229 37.6363 7.11783 37.8172C7.03337 37.9981 6.98587 38.194 6.97812 38.3935C6.97037 38.593 7.00252 38.7921 7.07269 38.979C7.14286 39.1659 7.24963 39.3369 7.38675 39.482C7.52387 39.6271 7.68857 39.7434 7.87121 39.824C8.05385 39.9046 8.25075 39.948 8.45036 39.9515C8.64997 39.9551 8.84829 39.9187 9.03367 39.8446C9.21906 39.7705 9.38779 39.6602 9.52997 39.52L13 36.22V71.5C13 71.8979 13.158 72.2794 13.4393 72.5607C13.7206 72.842 14.1021 73 14.5 73H65.5C65.8978 73 66.2793 72.842 66.5606 72.5607C66.8419 72.2794 67 71.8979 67 71.5V36.22L70.47 39.52C70.7608 39.7834 71.1432 39.9224 71.5352 39.9072C71.9273 39.892 72.2978 39.7237 72.5672 39.4386C72.8367 39.1534 72.9837 38.774 72.9768 38.3817C72.9698 37.9894 72.8094 37.6155 72.53 37.34V37.34ZM64 70H16V33.37L40 10.57L64 33.37V70Z"
    />
    <path d="M39 17.41L20.55 34.91C20.402 35.044 20.2823 35.2062 20.1978 35.3871C20.1134 35.568 20.0659 35.7639 20.0581 35.9634C20.0504 36.1629 20.0825 36.362 20.1527 36.5489C20.2229 36.7358 20.3296 36.9068 20.4668 37.0519C20.6039 37.197 20.7686 37.3133 20.9512 37.3939C21.1339 37.4745 21.3308 37.5179 21.5304 37.5214C21.73 37.525 21.9283 37.4886 22.1137 37.4146C22.2991 37.3405 22.4678 37.2301 22.61 37.09L40 20.57L57.39 37.09C57.5322 37.2301 57.7009 37.3405 57.8863 37.4146C58.0717 37.4886 58.27 37.525 58.4696 37.5214C58.6692 37.5179 58.8661 37.4745 59.0488 37.3939C59.2314 37.3133 59.3961 37.197 59.5332 37.0519C59.6703 36.9068 59.7771 36.7358 59.8473 36.5489C59.9174 36.362 59.9496 36.1629 59.9418 35.9634C59.9341 35.7639 59.8866 35.568 59.8021 35.3871C59.7177 35.2062 59.598 35.044 59.45 34.91L41 17.41C40.726 17.1619 40.3696 17.0245 40 17.0245C39.6304 17.0245 39.274 17.1619 39 17.41Z" />
  </Illustration>
);
