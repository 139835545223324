import React, { FC } from 'react';

import { iconColors } from '../../themes/eneco/tokens';
import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const Gas: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path
      d="M51.4 14.29C48.2963 10.5534 44.7618 7.19682 40.87 4.29004C40.616 4.10916 40.3119 4.01196 40 4.01196C39.6881 4.01196 39.384 4.10916 39.13 4.29004C35.2382 7.19682 31.7037 10.5534 28.6 14.29C21.67 22.57 18 31.46 18 39.99C18 48.52 21.67 57.41 28.6 65.7C31.7092 69.4315 35.2431 72.7875 39.13 75.7C39.3862 75.8755 39.6895 75.9693 40 75.9693C40.3105 75.9693 40.6138 75.8755 40.87 75.7C44.7569 72.7875 48.2908 69.4315 51.4 65.7C58.33 57.42 62 48.53 62 39.99C62 31.45 58.33 22.57 51.4 14.29ZM40 72.62C36.17 69.62 21 56.62 21 40C21 23.38 36.17 10.38 40 7.38004C43.83 10.38 59 23.38 59 40C59 56.62 43.83 69.62 40 72.62ZM41.18 27.56C41.0336 27.3907 40.8524 27.2548 40.6488 27.1617C40.4452 27.0686 40.2239 27.0204 40 27.0204C39.7761 27.0204 39.5548 27.0686 39.3512 27.1617C39.1476 27.2548 38.9664 27.3907 38.82 27.56C38.5 27.99 31 37.55 31 46.99C31 56.43 38.5 65.99 38.82 66.43C38.9614 66.6072 39.141 66.7502 39.3452 66.8485C39.5495 66.9468 39.7733 66.9979 40 66.9979C40.2267 66.9979 40.4505 66.9468 40.6548 66.8485C40.859 66.7502 41.0386 66.6072 41.18 66.43C41.5 65.99 49 56.38 49 46.99C49 37.6 41.5 27.99 41.18 27.56V27.56ZM40 62.9C38 59.9 34 53.21 34 46.97C34 40.73 38 34.07 40 31.06C42 34.06 46 40.72 46 46.97C46 53.22 42 59.89 40 62.9Z"
      fill={color ?? iconColors.iconGas}
    />
  </Illustration>
);
