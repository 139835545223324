import React, { FC } from 'react';

import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const Apartment: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path
      opacity="0.3"
      d="M11.5 70C11.1022 70 10.7206 69.842 10.4393 69.5607C10.158 69.2794 10 68.8978 10 68.5V11.5C10 11.1022 10.158 10.7206 10.4393 10.4393C10.7206 10.158 11.1022 10 11.5 10C11.8978 10 12.2794 10.158 12.5607 10.4393C12.842 10.7206 13 11.1022 13 11.5V68.5C13 68.8978 12.842 69.2794 12.5607 69.5607C12.2794 69.842 11.8978 70 11.5 70ZM70 68.5V11.5C70 11.1022 69.842 10.7206 69.5607 10.4393C69.2794 10.158 68.8978 10 68.5 10C68.1022 10 67.7206 10.158 67.4393 10.4393C67.158 10.7206 67 11.1022 67 11.5V68.5C67 68.8978 67.158 69.2794 67.4393 69.5607C67.7206 69.842 68.1022 70 68.5 70C68.8978 70 69.2794 69.842 69.5607 69.5607C69.842 69.2794 70 68.8978 70 68.5Z"
    />
    <path d="M68.5 55.5H11.5C11.1022 55.5 10.7206 55.342 10.4393 55.0607C10.158 54.7794 10 54.3978 10 54V26C10 25.6022 10.158 25.2206 10.4393 24.9393C10.7206 24.658 11.1022 24.5 11.5 24.5H68.5C68.8978 24.5 69.2794 24.658 69.5607 24.9393C69.842 25.2206 70 25.6022 70 26V54C70 54.3978 69.842 54.7794 69.5607 55.0607C69.2794 55.342 68.8978 55.5 68.5 55.5ZM13 52.5H67V27.5H13V52.5Z" />
  </Illustration>
);
