import React, { FC } from 'react';

import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const Oven: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.6272 6.88501C65.6754 6.88501 68.1465 9.35608 68.1465 12.4043V67.5973C68.1465 70.6455 65.6754 73.1166 62.6272 73.1166H17.3689C14.3207 73.1166 11.8496 70.6455 11.8496 67.5973V12.4043C11.8496 9.35608 14.3207 6.88501 17.3689 6.88501H62.6272ZM65.0996 23.7324H14.8965V70.173H65.0996V23.7324ZM65.0996 9.82864H14.8965V20.7888H65.0996V9.82864Z"
    />
    <path d="M39.9982 19.6932C42.3869 19.6932 44.3305 17.7262 44.3305 15.3084C44.3305 12.8909 42.3869 10.9241 39.9982 10.9241C37.6094 10.9241 35.666 12.8909 35.666 15.3084C35.666 17.7262 37.6094 19.6932 39.9982 19.6932ZM39.9982 13.8677C40.7832 13.8677 41.4222 14.5139 41.4222 15.3084C41.4222 16.103 40.7834 16.7495 39.9982 16.7495C39.2129 16.7495 38.5743 16.103 38.5743 15.3084C38.5743 14.5139 39.2131 13.8677 39.9982 13.8677Z" />
    <path d="M59.8826 35.2806H20.1163C19.3132 35.2806 18.6621 35.9395 18.6621 36.7524V64.8914C18.6621 65.7042 19.3132 66.3632 20.1163 66.3632H59.8826C60.6858 66.3632 61.3368 65.7042 61.3368 64.8914V36.7524C61.3368 35.9395 60.6858 35.2806 59.8826 35.2806ZM58.4285 63.4196H21.5704V38.2242H58.4285V63.4196Z" />
    <path d="M59.8826 28.3612H20.1163C19.3132 28.3612 18.6621 29.0202 18.6621 29.8331C18.6621 30.6459 19.3132 31.3049 20.1163 31.3049H59.8826C60.6858 31.3049 61.3368 30.6459 61.3368 29.8331C61.3368 29.02 60.6858 28.3612 59.8826 28.3612Z" />
  </Illustration>
);
