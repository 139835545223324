import React, { FC } from 'react';

import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const Quooker: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path d="M68.9496 43.1099C68.9495 43.1094 68.9494 43.1088 68.9492 43.1084H68.9503L67.8391 38.901C66.4564 33.6778 61.178 30.5791 56.0402 31.9745L32.4294 38.412V25.3127C32.4294 24.8242 32.7493 24.3954 33.2119 24.2641L49.9576 19.5044C53.9333 18.3691 56.2601 14.1799 55.1645 10.1301L54.6239 8.14726C54.4723 7.59274 53.9226 7.2541 53.3693 7.37415L19.0889 14.8178C15.6396 15.5819 13.175 18.6805 13.1605 22.2713V66.3169C11.8819 66.7767 11.0252 68.0039 11.0195 69.3833V71.561C11.0195 72.1624 11.4988 72.6499 12.09 72.6499H33.4999C34.0911 72.6499 34.5704 72.1624 34.5704 71.561V69.3833C34.5647 68.0039 33.7079 66.7767 32.4294 66.3169V54.1899L52.681 48.6714L52.9593 49.7266C53.4133 51.4662 55.1678 52.5022 56.8781 52.0403C56.8832 52.039 56.8883 52.0376 56.8933 52.0361L65.165 49.7832C66.8781 49.3157 67.8941 47.524 67.4345 45.7815L67.1562 44.7253L68.1924 44.4433C68.7635 44.2878 69.1026 43.6909 68.9496 43.1099ZM15.3015 35.6276H30.2884V66.1166H15.3015V35.6276ZM32.4294 70.4721H13.1605V69.3833C13.1605 68.7819 13.6398 68.2944 14.231 68.2944H31.3589C31.9501 68.2944 32.4294 68.7819 32.4294 69.3833V70.4721ZM30.2884 25.3125V33.4498H15.3015V22.2713C15.3123 19.7064 17.0726 17.4931 19.5364 16.9466L52.8287 9.71744L53.0995 10.7116C53.8808 13.6042 52.2194 16.5958 49.3806 17.4083L32.636 22.1678C31.2502 22.5642 30.2916 23.8484 30.2884 25.3125ZM64.6105 47.6784V47.6816L56.3388 49.9367C55.768 50.0931 55.1806 49.7492 55.0268 49.1684C55.0267 49.1678 55.0265 49.1673 55.0264 49.1669L54.7502 48.115L65.0912 45.297L65.3684 46.3488C65.5188 46.9287 65.18 47.5232 64.6105 47.6784ZM32.4294 51.937V40.667L56.5936 34.077C60.5895 32.9945 64.6933 35.4038 65.7709 39.4649L66.6048 42.6227L32.4294 51.937Z" />
    <path d="M63.2825 54.6092L62.4015 53.3112L61.5205 54.6092C60.9531 55.4454 55.9785 62.8641 55.9785 66.1166C55.9785 69.7249 58.8541 72.6499 62.4015 72.6499C65.9488 72.6499 68.8244 69.7249 68.8244 66.1166C68.8244 62.8641 63.8498 55.4454 63.2825 54.6092ZM62.4015 70.4721C60.0366 70.4721 58.1195 68.5221 58.1195 66.1166C58.1195 64.443 60.4746 60.2061 62.4015 57.1877C64.3284 60.2072 66.6834 64.444 66.6834 66.1166C66.6834 68.5221 64.7663 70.4721 62.4015 70.4721Z" />
  </Illustration>
);
