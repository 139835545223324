import React, { FC } from 'react';

import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const Microwave: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path d="M68.9611 18.75H11.0389C8.81174 18.75 7 20.5388 7 22.7377V57.2694C7 59.4683 8.81174 61.2571 11.0389 61.2571H12.7096H16.5768H63.4232H67.2904H68.9611C71.1883 61.2571 73 59.4683 73 57.2694V22.7377C73 20.5388 71.1883 18.75 68.9611 18.75ZM10.8672 57.2694V22.7377C10.8672 22.6442 10.9442 22.5682 11.0389 22.5682H53.7945V57.4389H11.0389C10.9442 57.4389 10.8672 57.3629 10.8672 57.2694ZM69.1328 57.2694C69.1328 57.3629 69.0558 57.4389 68.9611 57.4389H57.6617V22.5682H68.9611C69.0558 22.5682 69.1328 22.6442 69.1328 22.7377V57.2694Z" />
    <path d="M45.114 25.3821H19.5468C16.3322 25.3821 13.7168 27.9644 13.7168 31.1382V48.8689C13.7168 52.0428 16.3322 54.625 19.5468 54.625H45.114C48.3286 54.625 50.9435 52.0428 50.9435 48.8689V31.1382C50.9435 27.9644 48.3281 25.3821 45.114 25.3821ZM47.0763 48.8689C47.0763 49.9373 46.1961 50.8064 45.1135 50.8064H19.5468C18.4647 50.8064 17.584 49.9373 17.584 48.8689V31.1382C17.584 30.0699 18.4647 29.2003 19.5468 29.2003H45.114C46.1961 29.2003 47.0763 30.0699 47.0763 31.1382V48.8689Z" />
    <path d="M66.3344 47.1214C66.3344 48.7237 65.0186 50.0228 63.3957 50.0228C61.7728 50.0228 60.457 48.7237 60.457 47.1214C60.457 45.519 61.7728 44.2199 63.3957 44.2199C65.0186 44.2199 66.3344 45.519 66.3344 47.1214Z" />
    <path d="M66.3344 38.613C66.3344 40.2153 65.0186 41.5144 63.3957 41.5144C61.7728 41.5144 60.457 40.2153 60.457 38.613C60.457 37.0107 61.7728 35.7116 63.3957 35.7116C65.0186 35.7116 66.3344 37.0107 66.3344 38.613Z" />
    <path d="M65.356 28.0752H61.4375C60.37 28.0752 59.5039 28.9298 59.5039 29.9843C59.5039 31.0388 60.37 31.8934 61.4375 31.8934H65.356C66.4241 31.8934 67.2896 31.0388 67.2896 29.9843C67.2896 28.9298 66.4241 28.0752 65.356 28.0752Z" />
  </Illustration>
);
