import React, { FC } from 'react';

import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const CornerHouse: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path
      opacity="0.3"
      d="M75.69 25.59L64.22 10.59C64.0777 10.4022 63.893 10.2508 63.6809 10.1483C63.4689 10.0457 63.2355 9.9949 63 10C62.7698 10.0011 62.5429 10.0549 62.3366 10.1571C62.1304 10.2594 61.9502 10.4075 61.81 10.59L51.5 24L41.22 10.59C41.0777 10.4022 40.893 10.2508 40.6809 10.1483C40.4689 10.0457 40.2355 9.9949 40 10C39.7698 10.0011 39.5429 10.0549 39.3366 10.1571C39.1304 10.2594 38.9502 10.4075 38.81 10.59L27.28 25.59C27.0789 25.8507 26.9699 26.1707 26.97 26.5V68.5C26.9699 68.6995 27.0097 68.8971 27.087 69.081C27.1642 69.265 27.2774 69.4317 27.4199 69.5714C27.5623 69.7111 27.7313 69.821 27.9167 69.8945C28.1022 69.9681 28.3005 70.004 28.5 70H74.5C74.8978 70 75.2793 69.842 75.5606 69.5607C75.8419 69.2794 76 68.8978 76 68.5V26.5C76 26.1707 75.891 25.8507 75.69 25.59V25.59ZM30 27L40 14L50 27V67H30V27ZM73 67H53V27L63 14L73 27V67Z"
    />
    <path d="M28.5 70H5.5C5.10218 70 4.72064 69.842 4.43934 69.5607C4.15804 69.2794 4 68.8978 4 68.5V26.5C3.99995 26.1707 4.10896 25.8508 4.31 25.59L15.84 10.59C15.9761 10.4103 16.1512 10.2639 16.3522 10.1617C16.5531 10.0595 16.7746 10.0042 17 10C17.2305 9.99961 17.4579 10.0527 17.6644 10.1551C17.8709 10.2574 18.0508 10.4063 18.19 10.59L29.66 25.59C29.861 25.8508 29.97 26.1707 29.97 26.5V68.5C29.9701 68.8927 29.8162 69.2697 29.5413 69.5502C29.2665 69.8307 28.8926 69.9922 28.5 70V70ZM7 67H27V27L17 14L7 27V67Z" />{' '}
  </Illustration>
);
