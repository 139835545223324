import React, { FC } from 'react';

import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const InsulationWindow: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path
      opacity=".4"
      d="M72.53 37.34L41 7.41004C40.7227 7.14476 40.3538 6.9967 39.97 6.9967C39.5863 6.9967 39.2173 7.14476 38.94 7.41004L7.47004 37.34C7.32207 37.4741 7.20235 37.6363 7.11789 37.8172C7.03343 37.9981 6.98593 38.194 6.97818 38.3935C6.97043 38.593 7.00258 38.7921 7.07275 38.979C7.14292 39.1659 7.24969 39.3369 7.38681 39.482C7.52393 39.6271 7.68864 39.7434 7.87127 39.824C8.05391 39.9046 8.25081 39.948 8.45042 39.9515C8.65004 39.9551 8.84835 39.9187 9.03373 39.8446C9.21912 39.7705 9.38785 39.6602 9.53004 39.52L13 36.22V71.5C13 71.8979 13.1581 72.2794 13.4394 72.5607C13.7207 72.842 14.1022 73 14.5 73H65.5C65.8979 73 66.2794 72.842 66.5607 72.5607C66.842 72.2794 67 71.8979 67 71.5V36.22L70.47 39.52C70.7608 39.7834 71.1432 39.9224 71.5353 39.9072C71.9273 39.892 72.2978 39.7237 72.5673 39.4386C72.8368 39.1534 72.9838 38.774 72.9768 38.3817C72.9698 37.9894 72.8095 37.6155 72.53 37.34V37.34ZM64 70H16V33.37L40 10.57L64 33.37V70Z"
    />
    <path d="M35.5 37H23.5C23.1022 37 22.7206 37.158 22.4393 37.4393C22.158 37.7206 22 38.1022 22 38.5V50.5C22 50.8978 22.158 51.2794 22.4393 51.5607C22.7206 51.842 23.1022 52 23.5 52H35.5C35.8978 52 36.2794 51.842 36.5607 51.5607C36.842 51.2794 37 50.8978 37 50.5V38.5C37 38.1022 36.842 37.7206 36.5607 37.4393C36.2794 37.158 35.8978 37 35.5 37ZM34 49H25V40H34V49ZM56.5 37H44.5C44.1022 37 43.7206 37.158 43.4393 37.4393C43.158 37.7206 43 38.1022 43 38.5V50.5C43 50.8978 43.158 51.2794 43.4393 51.5607C43.7206 51.842 44.1022 52 44.5 52H56.5C56.8978 52 57.2794 51.842 57.5607 51.5607C57.842 51.2794 58 50.8978 58 50.5V38.5C58 38.1022 57.842 37.7206 57.5607 37.4393C57.2794 37.158 56.8978 37 56.5 37ZM55 49H46V40H55V49Z" />
  </Illustration>
);
