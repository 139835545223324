import React, { FC } from 'react';

import { Illustration, IllustrationProps } from '../IllustrationWrapper';

export const TerracedHouse: FC<IllustrationProps> = ({ size, color }) => (
  <Illustration size={size} color={color}>
    <path
      opacity="0.4"
      d="M75.69 25.59L64.22 10.59C64.0778 10.4022 63.8931 10.2508 63.681 10.1483C63.4689 10.0457 63.2356 9.9949 63 10C62.7698 10.0011 62.5429 10.0549 62.3367 10.1571C62.1304 10.2594 61.9503 10.4075 61.81 10.59L50.28 25.59C50.079 25.8507 49.97 26.1707 49.97 26.5V68.5C49.97 68.6995 50.0098 68.8971 50.087 69.081C50.1643 69.265 50.2775 69.4317 50.4199 69.5714C50.5624 69.7111 50.7313 69.821 50.9168 69.8945C51.1023 69.9681 51.3005 70.004 51.5 70H74.5C74.8979 70 75.2794 69.842 75.5607 69.5607C75.842 69.2794 76 68.8978 76 68.5V26.5C76.0001 26.1707 75.8911 25.8507 75.69 25.59V25.59ZM73 67H53V27L63 14L73 27V67ZM18.22 10.59C18.0778 10.4022 17.8931 10.2508 17.681 10.1483C17.4689 10.0457 17.2356 9.9949 17 10C16.7698 10.0011 16.5429 10.0549 16.3367 10.1571C16.1304 10.2594 15.9503 10.4075 15.81 10.59L4.28004 25.59C4.0897 25.8545 3.99134 26.1742 4.00004 26.5V68.5C4.00004 68.8978 4.15808 69.2794 4.43938 69.5607C4.72069 69.842 5.10222 70 5.50004 70H28.5C28.8979 70 29.2794 69.842 29.5607 69.5607C29.842 69.2794 30 68.8978 30 68.5V26.5C30.0001 26.1707 29.8911 25.8507 29.69 25.59L18.22 10.59ZM27 67H7.00004V27L17 14L27 27V67Z"
    />
    <path d="M51.5 70H28.5C28.1022 70 27.7206 69.842 27.4393 69.5607C27.158 69.2794 27 68.8978 27 68.5V26.5C27 26.1707 27.109 25.8508 27.31 25.59L38.84 10.59C38.9761 10.4103 39.1512 10.2639 39.3522 10.1617C39.5531 10.0595 39.7746 10.0042 40 10C40.2305 9.99961 40.4579 10.0527 40.6644 10.1551C40.8709 10.2574 41.0508 10.4063 41.19 10.59L52.66 25.59C52.861 25.8508 52.97 26.1707 52.97 26.5V68.5C52.9701 68.8927 52.8162 69.2697 52.5413 69.5502C52.2665 69.8307 51.8926 69.9922 51.5 70V70ZM30 67H50V27L40 14L30 27V67Z" />{' '}
  </Illustration>
);
